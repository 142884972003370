<template>
 <div>
        <Header />
        <br /><br /><br /><br /><br /><br /><br />
        <div class="container fluid">
          <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/mini_applicators/standard">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://kmcorporate.com/wp-content/uploads/2021/07/Miniapplicatore-Meccanico.jpg"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Standard Mini Applicator
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/mini_applicators/pneumatic">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://kmcorporate.com/wp-content/uploads/2021/07/Miniapplicatore-Pneumatico.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Pneumatic Mini Applicator
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/mini_applicators/ferrules">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://www.kmcorporate.com/wp-content/uploads/2021/06/MA-Ferrules-Rev1-210610-1536x1044.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Ferruless Mini Applicator
              </h4>
            </div>
          </div>
          </router-link>
        </div>
          <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/mini_applicators/special">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://www.kmcorporate.com/wp-content/uploads/2021/06/MA-Special-tre-quarti-rev1-210610-1536x1044.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
              Special Mini Applicator
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/mini_applicators/splice">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://www.kmcorporate.com/wp-content/uploads/2021/06/MA-Splice-rev1-210607-1536x1044.png"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
               Splice Mini Applicator
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        
        </div>
      </div>
        <Footer />
    </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>